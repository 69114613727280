<script setup lang="ts">
import { computed } from 'vue';
import { CheckCircleIcon } from '@heroicons/vue/24/solid';
import Title from '@/Components/Title/Title.vue';

interface IActivity {
    id: number;
    causer_name?: string;
    description: string;
    event: string;
    properties?: any;
    created_at: string;
    created_at_local: string;
}

const props = defineProps<{
    shift: {
        activities: IActivity[];
    };
}>();

const activity = computed(() => {
    // return [
    //     { id: 1, type: 'created', person: { name: 'Chelsea Hagon' }, date: '7d ago', dateTime: '2023-01-23T10:32' },
    //     { id: 2, type: 'edited', person: { name: 'Chelsea Hagon' }, date: '6d ago', dateTime: '2023-01-23T11:03' },
    //     { id: 3, type: 'sent', person: { name: 'Chelsea Hagon' }, date: '6d ago', dateTime: '2023-01-23T11:24' },
    //     { id: 5, type: 'viewed', person: { name: 'Alex Curren' }, date: '2d ago', dateTime: '2023-01-24T09:12' },
    //     { id: 6, type: 'paid', person: { name: 'Alex Curren' }, date: '1d ago', dateTime: '2023-01-24T09:20' },
    // ];

    return props.shift.activities.map((activity: IActivity) => {
        return {
            id: activity.id,
            causer_name: activity.causer_name,
            properties: activity.properties,
            description: activity.description,
            event: activity.event,
            created_at: activity.created_at,
            created_at_local: activity.created_at_local,
        };
    });
});
</script>

<template>
    <Title :title="$t('Shift feed')" />
    <ul role="list" class="space-y-6">
        <li v-for="(activityItem, activityItemIdx) in activity" :key="activityItem.id" class="relative flex gap-x-4">
            <div
                :class="[
                    activityItemIdx === activity.length - 1 ? 'h-6' : '-bottom-6',
                    'absolute left-0 top-0 flex w-6 justify-center',
                ]"
            >
                <div class="w-px bg-gray-200" />
            </div>
            <div class="relative flex items-center justify-center flex-none w-6 h-6 bg-white">
                <CheckCircleIcon
                    v-if="activityItem.event === 'accredited'"
                    class="w-6 h-6 text-green-600"
                    aria-hidden="true"
                />
                <div v-else class="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
            </div>
            <div class="flex flex-row justify-between w-full">
                <div>
                    <h4 class="flex-auto py-0.5 text-base leading-5 text-blue">
                        {{ activityItem.description }}
                    </h4>

                    <div v-if="activityItem.causer_name">
                        <p class="flex-auto py-0.5 text-sm leading-5 text-blue">
                            {{ $t('Caused by: {name}', { name: activityItem.causer_name }) }}
                        </p>
                    </div>

                    <div v-if="activityItem.properties">
                        <div class="py-2" v-for="(properties, key) in activityItem.properties" :key="key">
                            <h5 class="text-sm">{{ key }}</h5>
                            <ul class="text-xs list-disc list-inside">
                                <li v-for="(value, key) in properties" :key="key">
                                    <span>{{ key }} - {{ value }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <time :datetime="activityItem.created_at_local" class="flex-none py-0.5 text-base leading-5 text-blue">
                    {{ activityItem.created_at }}
                </time>
            </div>
        </li>
    </ul>
</template>
